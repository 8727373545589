<template>
  <div v-if="item && message && item.key === message.key">
    <p class="has-text-weight-bold">
      <span class="icon">
        <i class="far" :class="item.icon" />
      </span>
      <span>{{ item.text }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'LoadingMessage',
  props: {
    message: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
